import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Seo from '../../components/seo';
import '../../scss/custom.scss';
import MainLayout from '../../layouts/main';
import { ArticleWrapper } from '../../components/article/styled';
import FeatureArticles from '../../components/widgets/featuredArticle';

const HomePage = () => {
  const query = graphql`
    query BookReviewPosts {
      allMdx(
        sort: {order: DESC, fields: frontmatter___date},
        filter: {fileAbsolutePath: {regex: "/\/books\//"}},
      ) {
        edges {
          node {
            id
            slug
            frontmatter {
              title
              category
              date(formatString: "MMMM Do, YYYY")
              description
              author
              hero_image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `;

  const data = useStaticQuery(query);
  const articles = data.allMdx.edges;
  const title = `Chanon's book reviews`;
  const description = `Review of IT, Business, personal development and e-commerce books that you should read in order to manage and run the online business`;

  return (
    <MainLayout>
      <Seo title={title} description={description} />
      <ArticleWrapper>
        <h1 className="title">My book reviews</h1>  
        <FeatureArticles articles={articles} />
      </ArticleWrapper>
    </MainLayout>
  )
};

export default HomePage;
